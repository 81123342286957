/* General */
*,
*::before,
*::after {
    box-sizing: border-box;
}

body {
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0;
    background-color:  #1a6bb3    ;       /*#227bc9; Color de fondo azul celeste claro */
    overflow-x: hidden; /* Evita el desplazamiento horizontal */
}


/* Centrar títulos */
h1, h2, h3, h4, h5, h6 {
    text-align: center; /* Centra los títulos */
}



.logo-image {
    max-height: 70px;
}



header, footer, main {
    width: 100%;
}



.header-menu {
    display: flex;
    align-items: center;          /* Alinea verticalmente los elementos hijos dentro del contenedor */
    padding: 10px 20px;   
    background-color: #87CEEB; /* Azul celeste */
    border-bottom: 1px solid #063238; 
    justify-content: center;     /*Centra horizontalmente los elementos hijos dentro del contenedor.*/
    position: relative;   /*Configura la posición del contenedor como relativa. Esto significa que el contenedor se posicionará de acuerdo con su flujo normal en el documento, pero puedes ajustar su posición con propiedades como top, right, bottom, left. Además, establece un contexto para cualquier elemento posicionado absolutamente dentro del contenedor.*/
}

.site-title {
    font-size: 32px; /* Tamaño de fuente mayor */
    font-weight: 800; /* Peso de fuente más fuerte */
    margin-left: 20px;
    flex: 1;
    text-align: center;
    color: #2459eb; /* Blanco para el texto */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3); /* Sombra para mejorar la visibilidad */
    letter-spacing: 1px; /* Espaciado entre letras */
    line-height: 1.2; /* Mayor altura de línea */
}

main {
    display: flex;
    justify-content: space-between;
    
    background-color: #dde4fc; /* Blanco para el fondo principal */
}

.content {
    width: 60%;
    background-color: #e9eefd; /* Color de fondo */
    padding: 10px; /* Añade espacio interno al contenido */
    border-radius: 8px; /* Bordes redondeados */
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.3); /* Sombra */
}

.contenedor-enlace {
    text-align: center; /* Centra el texto dentro del contenedor */
    margin-top: 20px; /* Espacio superior opcional */
}


/* Provincias */
/* Estilos para la lista de provincias */
.provincias {
    list-style-type: none; /* Elimina los puntos de la lista */
    padding: 0;
    margin: 0;
    margin-bottom: 20px;
}

.provincias a {
    display: inline-block;
    padding: 10px 12px;
    text-decoration: none; /* Elimina el subrayado del enlace */
    color: #005f99; /* Azul marino oscuro para el texto */
    background-color: #f0f8ff; /* Fondo blanco azulado para los enlaces */
    border-radius: 5px; /* Bordes redondeados */
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2); /* Sombra ligera para el enlace */
    transition: background-color 0.3s ease, color 0.3s ease; /* Transiciones suaves para el color de fondo y texto */
    margin: 2px;
}

.provincias a:hover {
    background-color: #007acc; /* Azul brillante al pasar el ratón */
    color: #ffffff; /* Texto blanco al pasar el ratón */
    text-decoration: none; /* Asegura que el subrayado no aparezca al pasar el ratón */
}

.provincias a:active {
    background-color: #005f99; /* Azul marino oscuro cuando el enlace es clickeado */
    color: #ffffff; /* Texto blanco cuando el enlace es clickeado */
}

.provincias a:visited {
    color: #003d66; /* Azul oscuro para enlaces visitados */
}

/* Localidades Aleatorias */
.localidades {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Distribuir de manera flexible */
    gap: 5px; /* Espacio entre las celdas */
    padding: 5px;
}

.localidad {
    padding: 10px;
    text-align: center;
    overflow: hidden; /* Oculta el contenido desbordado */
    word-wrap: break-word; /* Ajusta las palabras largas */
    box-sizing: border-box; /* Asegura que padding y border se incluyan en el tamaño total del elemento */
}

/* Estilos para hacer que los divs ocupen diferentes tamaños */
.localidad.large {
    font-size: 24px; /* Tamaño de fuente para localidades grandes */
}

.localidad.medium {
    font-size: 18px; /* Tamaño de fuente para localidades medianas */
}

.localidad.small {
    font-size: 14px; /* Tamaño de fuente para localidades pequeñas */
}




.left-ad, .right-ad {
    width: 20%;
    background-color: #f0f8ff; /* Azul celeste claro */
    padding: 10px;
}



/* Footer */
footer {
    padding: 20px;
    background-color: #87CEEB; /* Azul celeste */
    text-align: center;
}

.footer-ad {
    background-color: #f0f8ff; /* Azul celeste claro */
    padding: 10px;
    text-align: center;
}

nav ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

nav ul li {
    display: inline;
    margin-right: 15px;
}

.link-footer {
    font-size: 14px;
    color: #004d99; /* Azul marino oscuro */
    text-decoration: none;
    transition: color 0.3s ease, text-decoration 0.3s ease;
}

.link-footer:hover {
    color: #ffcc00; /* Amarillo dorado al pasar el ratón por encima */
    text-decoration: underline; /* Subrayado al pasar el ratón por encima */
}


.legales p {
    text-align: justify; /* Texto justificado */
    margin-bottom: 15px; /* Espacio entre párrafos */
    line-height: 1.6; /* Espaciado de línea para mejorar la legibilidad */
}

.legales h2,
.legales h3 {
    text-align: left; /* Alinear los h2 y h3 a la izquierda */
    margin-top: 30px; /* Espacio antes del h2 y h3 */
    font-size: 1.5em; /* Tamaño de fuente para h2 y h3 */
    color: #333; /* Color de texto (opcional) */
}

.legales h3 {
    font-size: 1.2em; /* Tamaño de fuente más pequeño para h3 */
    margin-top: 20px; /* Menor espacio superior para h3 */
}



/* Búsqueda por Ubicación  */
.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #000;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite;
    margin: 0 auto;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/* Asegúrate de que el contenedor de resultado tenga una posición relativa */
#indicador_progreso {
    text-align: center; /* Alinea el texto en el centro */
}

/* Búsqueda por Ubicación  fin */

.instrucciones {
    background-color: #f9f9f9; /* Color de fondo suave */
    border: 1px solid #dde4fc; /* Borde azul claro */
    border-radius: 8px; /* Bordes redondeados */
    padding: 15px; /* Espacio interno */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Sombra ligera */
    margin: 20px auto; /* Espacio superior e inferior, centrado horizontalmente */
    width: 80%;
    text-align: center; /* Centrar el texto */
}

.instrucciones p {
    font-size: 16px; /* Tamaño de fuente cómodo */
    color: #333; /* Color de texto oscuro para buena legibilidad */
    margin: 0; /* Elimina el margen por defecto */
    line-height: 1.6; /* Espaciado de línea */
}

.opcion {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centra los elementos horizontalmente dentro del contenedor */
    justify-content: center; /* Centra los elementos verticalmente dentro del contenedor */
    background-color: #ffffff; /* Fondo blanco para destacar las opciones */
    border: 1px solid #dde4fc; /* Borde azul claro */
    border-radius: 8px; /* Bordes redondeados */
    padding: 15px; /* Espacio interno */
    margin: 15px 0; /* Espacio superior e inferior */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Sombra ligera */
    text-align: center; /* Centra el texto dentro del contenedor */

}

.opcion h2 {
    font-size: 22px; /* Tamaño de fuente para los subtítulos */
    color: #2459eb; /* Azul para los subtítulos */
    margin: 0 0 10px 0; /* Margen inferior */
}

.opcion p {
    font-size: 16px; /* Tamaño de fuente para el texto descriptivo */
    color: #333; /* Color de texto oscuro para buena legibilidad */
}


.no-subrayado {
    text-decoration: none;
    transition: color 0.3s ease;
}

.no-subrayado:hover {
    filter: brightness(1.2); /* Aumenta el brillo del color en un 20% */
}

.contenedor-codigo-postal {
    text-align: center;
    font-size: 2.5em; /* Tamaño de fuente grande para destacar */
    font-weight: bold; /* Negrita para mayor énfasis */
    padding: 20px; /* Espaciado interno para hacer el contenido más prominente */
    background-color: #f0f8ff; /* Fondo suave inspirado en la bandera argentina */
    border-radius: 10px; /* Bordes redondeados */
    margin: 20px auto; /* Centrar y agregar margen superior e inferior */
    width: 80%; /* Limitar el ancho al 80% del contenedor padre */
}

.contenedor-descripcion {
    text-align: justify;
    font-size: 1.2em; /* Tamaño de fuente más pequeño para reducir el énfasis */
    padding: 10px; /* Menor espaciado interno */
    background-color: #e0e0e0; /* Fondo más neutral y menos destacado */
    border-radius: 8px; /* Bordes redondeados */
    margin: 10px auto; /* Centrar y agregar un margen inferior menor */
    width: 70%; /* Limitar el ancho al 70% del contenedor padre */
}