/* Estilos para la tabla */
table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    background-color: #ffffff; /* Blanco */
    border-radius: 8px; /* Bordes redondeados */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Sombra suave */
    table-layout: auto;
}

th, td {
    padding: 15px;
    text-align: left;
    border-bottom: 1px solid #224755; /* Azul celeste claro */
    color: #333; /* Texto oscuro */
}

th {
    background-color: #145a75; /* Azul celeste */
    color: #ffffff; /* Texto blanco */
    text-transform: uppercase; /* Convertir el texto a mayúsculas */
    letter-spacing: 0.05em; /* Espaciado entre letras */
}

tr:hover {
    background-color: #f0f8ff; /* Fondo azul celeste claro al pasar el ratón por encima */
}

a.table-link {
    color: #007bff; /* Azul oscuro */
    text-decoration: none;
    transition: color 0.3s ease, text-decoration 0.3s ease;
}

a.table-link:hover {
    color: #0056b3; /* Azul más oscuro */
    text-decoration: underline;
}

/* Estilos para el paginador */
.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.pagination a, .pagination span {
    margin: 0 5px;
    padding: 10px 15px;
    border-radius: 4px;
    text-decoration: none;
    background-color: #87CEEB; /* Azul celeste */
    color: #ffffff; /* Blanco */
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.pagination a:hover {
    background-color: #0056b3; /* Azul más oscuro al hacer hover */
    transform: scale(1.05); /* Efecto de aumento al pasar el ratón */
}

.pagination .current {
    background-color: #004494; /* Azul más oscuro para la página actual */
    font-weight: bold;
}

.pagination .disabled {
    background-color: #c0c0c0; /* Gris para botones deshabilitados */
    cursor: not-allowed;
}

/* Responsividad para tablas */
@media (max-width: 768px) {
    table {
        font-size: 14px; /* Reducir el tamaño de fuente en pantallas pequeñas */
    }

    th, td {
        padding: 10px; /* Reducir el padding en pantallas pequeñas */
    }
}
