/* botones */
button, .btn {
    background-color: #007bff; /* Azul oscuro */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    text-decoration: none; /* Elimina el subrayado de los enlaces */
    transition: background-color 0.3s ease, transform 0.3s ease;
}

button:hover, .btn:hover {
    background-color: #0056b3; /* Color al hacer hover */
    transform: scale(1.05); /* Pequeño efecto de aumento al hacer hover */
}

button:active, .btn:active {
    background-color: #004494; /* Color más oscuro al hacer clic */
    transform: scale(0.98); /* Ligera reducción de tamaño al hacer clic */
}

button:disabled, .btn:disabled {
    background-color: #c0c0c0; /* Color gris para botones deshabilitados */
    cursor: not-allowed;
}


/* Estilos para el botón grande */
.botonazo {
    display: inline-flex; /* Usa flexbox para centrar el contenido dentro del botón */
    justify-content: center; /* Centra el texto horizontalmente dentro del botón */
    align-items: center; /* Centra el texto verticalmente dentro del botón */
    padding: 20px 40px; /* Ajusta el relleno para hacer el botón más grande */
    background-color: #007bff; /* Azul oscuro para el botón */
    color: #ffffff; /* Texto blanco */
    border-radius: 8px; /* Bordes redondeados */
    text-decoration: none; /* Elimina el subrayado */
    font-size: 18px; /* Tamaño de fuente */
    transition: background-color 0.3s ease, transform 0.3s ease;
    height: 80px; /* Fija una altura específica para el botón */
}

.botonazo:hover {
    background-color: #0056b3; /* Color más oscuro al pasar el ratón */
    transform: scale(1.05); /* Pequeño efecto de aumento al pasar el ratón */
}

.botonazo:active {
    background-color: #004494; /* Color más oscuro al hacer clic */
    transform: scale(0.98); /* Ligera reducción de tamaño al hacer clic */
}


/* Estilos para el botón pequeño */
.btn-chico {
    background-color: #007bff; /* Azul oscuro */
    color: white;
    padding: 5px 10px; /* Reduce el relleno para hacer el botón más pequeño */
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px; /* Disminuye el tamaño de la fuente */
    text-decoration: none; /* Elimina el subrayado de los enlaces */
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.btn-chico:hover {
    background-color: #0056b3; /* Color al hacer hover */
    transform: scale(1.05); /* Pequeño efecto de aumento al hacer hover */
}

.btn-chico:active {
    background-color: #004494; /* Color más oscuro al hacer clic */
    transform: scale(0.98); /* Ligera reducción de tamaño al hacer clic */
}

.btn-chico:disabled {
    background-color: #c0c0c0; /* Color gris para botones deshabilitados */
    cursor: not-allowed;
}