
/* Responsividad */
@media (max-width: 768px) {
    .header-menu {
        flex-direction: row;
        align-items: center;
        padding: 8px;
    }

    .logo-image {
        max-height: 60px;
    }

    .site-title {
        font-size: 40px;
        margin-left: 0;
    }

    .left-ad, .right-ad {
        width: 100%;
    }

    .content {
        width: 100%;
        margin: 0; /* Elimina margen lateral en pantallas pequeñas */
        padding: 10px; /* Reduce el padding interno en pantallas pequeñas */
    }

    nav ul {
        text-align: center;
    }

    nav ul li {
        display: block;
        margin: 5px 0;
    }
}

@media (max-width: 480px) {
    .logo-image {
        max-height: 50px;
    }

    .site-title {
        font-size: 30px;
    }

    .footer-ad {
        padding: 5px;
    }

    main {
        display: flex;
        flex-direction: column;
    }

    .content {
        order: 1;
        padding: 20px;
    }

    .left-ad {
        order: 2;
    }

    .right-ad {
        order: 3;
    }


}
