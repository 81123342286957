/* styles for resultado */
.resultado {
    background-color: #f5f5f5; /* color de fondo suave */
    border: 2px solid #007bff; /* borde azul para destacar */
    border-radius: 8px; /* bordes redondeados */
    padding: 20px; /* espaciado interno */
    margin: 20px 0; /* márgenes arriba y abajo */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* sombra para resaltar */
}

.resultado h1 {
    color: #007bff; /* color del título destacado */
}

.resultado p {
    font-size: 16px; /* tamaño de fuente legible */
    color: #333; /* color del texto */
}

.resultado .btn {
    display: inline-block;
    background-color: #007bff; /* color de fondo del botón */
    color: white; /* color del texto del botón */
    padding: 10px 20px; /* espaciado interno del botón */
    border-radius: 5px; /* bordes redondeados del botón */
    text-decoration: none; /* sin subrayado */
    margin-top: 10px; /* margen superior del botón */
}

.resultado .btn:hover {
    background-color: #0056b3; /* color de fondo del botón al pasar el ratón */
}

/* styles for dato-localidad */
.dato-localidad {
    background-color: #e9ecef; /* color de fondo más claro */
    border: 1px solid #ccc; /* borde gris claro */
    border-radius: 8px; /* bordes redondeados */
    padding: 15px; /* espaciado interno */
    margin: 20px 0; /* márgenes arriba y abajo */
}

.dato-localidad h2 {
    color: #555; /* color del título más suave */
}

.dato-localidad p {
    font-size: 14px; /* tamaño de fuente más pequeño */
    color: #666; /* color del texto más suave */
}
