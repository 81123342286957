.cp-container {
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.cp-title {
    font-size: 24px;
    color: #055e9e;
    margin-bottom: 20px;
}

.cp-highlight {
    font-weight: bold;
    color: #0c32af; /* Color del texto en blanco para contrastar con el fondo */
    background-color: #bbdbf0; /* Color de fondo para resaltar */
    padding: 5px 10px; /* Espaciado alrededor del texto */
    border-radius: 5px; /* Bordes redondeados para un efecto más suave */
    font-size: 28px; /* Tamaño de fuente más grande */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); /* Sombra sutil para mayor profundidad */
}


.cp-details {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.cp-item {
    display: flex;
    align-items: center;
    gap: 10px;
}

.cp-label {
    font-weight: bold;
    color: #333;
}

.cp-value {
    color: #666;
}

.cp-neighbor {
    margin-top: 20px;
    padding: 15px;
    background-color: #c3dfec;
    border-radius: 5px;
    font-size: 16px;
}

.btn-chico {
    display: inline-block;
    padding: 10px 20px;
    background-color: #0072bc;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    font-size: 14px;
    transition: background-color 0.3s ease;
}

.btn-chico:hover {
    background-color: #005fa3;
}
