form {
    display: flex;
    flex-direction: column; /* Cambia la dirección del flujo a columna */
    justify-content: center;
    align-items: center;
    margin: 50px auto;
}


.campo-busqueda {
    width: 93%;
    max-width: 600px;
    padding: 10px;
    border: 1px solid #dcdcdc;
    border-radius: 24px;
    font-size: 16px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    margin-bottom: 10px; /* Agrega un margen inferior para separar el botón */
}
